import React from "react";
import { StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout";

export default () => (
  <StaticQuery
    query={graphql`
      query BlogQueryLatestPage {
        allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}, limit: 1,
          filter: {frontmatter: {title: {ne: "EMPTY?"}}}) {
          edges {
            node {
              frontmatter {
                slug
                title
                template
                date(formatString: "MMMM DD, YYYY")
              }
              id
              excerpt
              html
            }
          }
        }
      }
    `}
    render={data => {
      let node=data.allMarkdownRemark.edges[0].node;
      return (
        <Layout>
        <div className="blog-post-container">
          
          <div className="blog-post">
            <h1>{node.frontmatter.title}</h1><hr/>
            <h2>{node.frontmatter.date}</h2>
            <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{ __html: node.html }}
            />
          </div>
        </div>
      </Layout>
    )}}
  />
)
